<template>
  <div class="block-area mb-0">
    <div class="border-bottom-last-0 first-pt-0">
      <router-link :to="{ name:'Articulo', params: {urlId:news.urlId} }">
        <article
          class="card card-full hover-a py-4 post-1285 post type-post status-publish
           format-standard has-post-thumbnail hentry category-fashion tag-adventure tag-style">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="ratio_360-102 text-sm-center" style="overflow: hidden">
                <a href="">
                  <img width="360" height="202"
                       :src="news.imageUrl"
                       sizes="(max-width: 360px) 100vw, 360px"
                       class="img-fluid d-block"/>
                </a>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 ">
              <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
                <div class="card-text mb-2 text-muted small">
                  <div class="news-date">
                    <DateTime :date="new Date(news.createdAt)"></DateTime>
                  </div>
                </div>
                <a class="card-title h2 h3-sm h2-md">
                  {{ news.title }}
                </a>
                <p class="card-text">
                  {{ textPreview }}</p>
              </div>
              <div class="ocultar">
                <br/>
              </div>
              <div class="my-3 ocultar">
                <!--<div class="my-3">
                  <div class="vermas d-flex boton">
                    Ver más...
                    <BotonCompartirFacebook></BotonCompartirFacebook>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </article>
      </router-link>
    </div>
  </div>
  <div class="block-title-13"><h4 class="h5 title-box-dot"></h4>
    <div class="dot-line mb-1"></div>
  </div>
  <div class="gap-0"></div>
</template>

<script>
import DateTime from './DateTime.vue';

export default {
  name: 'ArchivoNoticias',
  components: { DateTime },
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
  computed: {
    textPreview() {
      const palabras = this.news.text.split(' ');
      const palabrasDentroDelRango = [];
      let totalLetras = 0;
      const MAXIMO_LETRAS = 150;

      // eslint-disable-next-line no-restricted-syntax
      for (const palabra of palabras) {
        totalLetras += palabra.length;

        if (totalLetras > MAXIMO_LETRAS) {
          break;
        }

        palabrasDentroDelRango.push(palabra);
      }

      return `${palabrasDentroDelRango.join(' ')}...`;
    },
  },
};
</script>

<style scoped>
.img-fluid{
  width: 100%;
  height: 202px;
}
.event-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}

.event-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.compartir {
  width: 100%;
  text-align: left;
  justify-content: flex-end;

}

.parrafo {
  text-align: justify;
  width: 95%;
  line-height: 35px;
}

.boton {
  display: flex;
  justify-content: space-between;
  vertical-align: bottom;
  width: 45%;
  bottom: 0;
  position: absolute;
}

.margen {
  margin-left: 25px;
}

.vermas {
  cursor: pointer;
}

.vermas:hover {
  color: blue;
  font-size: medium;
}

@media screen and (max-width: 570px) {
  img {
    width: 95%;
    margin: auto;
    height: 202px;
  }

  .boton {
    display: flex;
    justify-content: space-around;
    vertical-align: bottom;
    width: 90%;
    bottom: 0;
    position: absolute;
  }

  .block-area {
    margin: 0;
    padding: 0;
  }

  h4 {
    margin: 0;;
  }
  .ocultar{
    display: none;
  }
}

@media (max-width: 768px) {
  .boton {
    display: flex;
    justify-content: space-around;
    vertical-align: bottom;
    width: 45%;
    bottom: 0;
    position: absolute;
  }
}

@media screen and (max-width: 992px) {
  img {
    width: 95%;
    margin: auto;
  }

  .boton {
    display: flex;
    justify-content: space-around;
    vertical-align: bottom;
    width: 90%;
    bottom: 0;
    position: absolute;
  }

  .block-area {
    margin: 0;
    padding: 0;
  }

  h4 {
    margin: 0;;
  }
}
</style>
