<template>
  <header class="header">
    <div class="top-menu bg-white second-header wrapper">
      <div class="container">
        <main id="content">
          <div>
            <div>
              <div class="top-section col-12 mt-05">
                <div class="gap-075"></div>
                <div class="mb-3 container fondo">
                  <div class="row">
                    <NoticiaCarrusel v-for="_news in news" :key="_news.urlId" :news="_news"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </header>
</template>

<script>
import NoticiaCarrusel from './NoticiaCarrusel.vue';

export default {
  name: 'Carrusel',
  components: { NoticiaCarrusel },
  props: {
    news: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
