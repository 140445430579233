<template>
  <header class="header">
    <div class="bg-white wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-8 my-3">
            <h1 class="text-center"> Publicidades</h1>
            <div v-if="publicidadPrincipal" class="">
              <div id="custom_html-1" class="widget_text my-2 my-md-3 my-lg-4
               d-none d-md-block text-center">
                <div class="textwidget custom-html-widget boton-container">
                  <a
                    :href="publicidadPrincipal.linkUrl" target="_blank"
                    rel="noopener noreferrer">
                    <img width="628" height="120"
                         :src="publicidadPrincipal.imageUrl"
                         sizes="(max-width: 628px) 100vw, 628px"/>
                  </a>
                  <div>
                    <button class="btn btn-white active"
                            @click="borrarPublicidadPrincipal(publicidadPrincipal.id)">X
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="contenedor">
              <form @submit.prevent="guardarPublicidasMain" ref="formulario-cargar-articulo">
                <h1><b class="mb-4 text-center">Imagen 728 x 120- Publicidad principal</b></h1>
                <label class="form-label mb-1" for="">URL</label>
                <input type="text" class="form-control mb-3"
                       v-model="linkUrlPublicidadPrincipal"/>
                <div class="text-danger" v-if="v$.linkUrlPublicidadPrincipal.$invalid">
                  URL incorrecta
                </div>
                <div class="form-outline">
                  <CargadorImagen
                    @imagen-cargada="imagenPublicidadPrincipalCargada"></CargadorImagen>
                  <div class="text-danger" v-if="v$.urlImagenPublicidadPrincipal.$invalid">
                  </div>
                </div>
                <div class="d-grid gap-2 col-6 mx-auto">
                  <input class="btn btn-primary my-3"
                         type="submit"
                         :disabled="deshabilitarBotonMain"
                         value="Subir"/>
                </div>
                <div class="alert alert-success text-center mt-5" role="alert" v-if="show">
                  ¡Publicidad cargada con éxito!<br/>
                </div>
                <br/>
                <br/>
              </form>
            </div>
            <div class="contenedor">
              <form @submit.prevent="guardarPublidadesSecundaria" ref="formulario-cargar-articulo">
                <h1><b class="my-3">Imagenes 360 x 202- Publicidades secundarias</b></h1>
                <label class="form-label mb-3" for="">URL:</label>
                <input type="text" id="" class="form-control mb-3"
                       v-model="linkUrlPublicidadSecundaria"/>
                <div class="text-danger" v-if="v$.linkUrlPublicidadSecundaria.$invalid">
                  URL incorrecta
                </div>
                <div class="form-outline">
                  <CargadorImagen @imagen-cargada="imagenPublicidadSecundariaCargada">
                  </CargadorImagen>
                  <div class="text-danger" v-if="v$.urlImagenPublicidadSecundaria.$invalid">
                    Campo obligatorio
                  </div>
                </div>
                <div class="d-grid gap-2 col-6 mx-auto">
                  <input class="btn btn-primary my-2" type="submit"
                         :disabled="deshabilitarBotonSsecundarias" value="Subir"/>
                </div>
                <div class="alert alert-success text-center mt-5" role="alert"
                     v-if="show2">
                  ¡Publicidad cargada con éxito!<br/>
                </div>
                <br/>
              </form>
            </div>
          </div>
          <div class="col-md-4">
            <draggable :list="publicidadesSecundarias">
              <article v-for="publicidad in publicidadesSecundarias" :key="publicidad.imageUrl"
                       class="card card-full hover-a mb-4">
                <div class="ratio_360-202 boton-container" style="overflow: hidden">
                  <a :href="publicidad.linkUrl" target="_blank" rel="noopener noreferrer">
                    <img width="360" height="202"
                         :src="publicidad.imageUrl"
                         sizes="(max-width: 360px) 100vw, 360px"
                         class="publicidad"/>
                  </a>
                  <div>
                    <button class="btn btn-white active"
                            @click="borraPublicidadSecundaria(publicidad.id)">X
                    </button>
                  </div>
                </div>
              </article>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import CargadorImagen from '@/components/CargadorImagen.vue';
import useVuelidate from '@vuelidate/core';
import { url } from '@vuelidate/validators';
import advertisementService from '@/services/AdvertisementService';
import { VueDraggableNext } from 'vue-draggable-next';

export default {
  name: 'CargarPublicidad',
  components: {
    CargadorImagen,
    draggable: VueDraggableNext,
  },
  setup: () => ({ v$: useVuelidate() }),
  created() {
    advertisementService.getAdvertisements()
      .then((response) => {
        this.publicidadPrincipal = response.data.main;
        this.publicidadesSecundarias = response.data.secondaries;
      });
  },
  data() {
    return {
      show: false,
      show2: false,
      publicidadPrincipal: null,
      publicidadesSecundarias: [],
      linkUrlPublicidadPrincipal: '',
      urlImagenPublicidadPrincipal: '',
      linkUrlPublicidadSecundaria: '',
      urlImagenPublicidadSecundaria: '',
    };
  },
  computed: {
    deshabilitarBotonSsecundarias() {
      return this.v$.$invalid;
    },
    deshabilitarBotonMain() {
      return this.v$.$invalid;
    },
  },
  methods: {
    borrarPublicidadPrincipal(id) {
      this.publicidadPrincipal = null;
      advertisementService.deleteAdvertisement(id);
    },
    borraPublicidadSecundaria(id) {
      this.publicidadesSecundarias = this.publicidadesSecundarias
        .filter((publicidad) => publicidad.id !== id);
      advertisementService.deleteAdvertisement(id);
    },
    guardarPublicidasMain() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      advertisementService.createAdvertisement({
        type: 'main',
        imageUrl: this.urlImagenPublicidadPrincipal,
        linkUrl: this.linkUrlPublicidadPrincipal,
      })
        .then((response) => {
          this.publicidadPrincipal = response.data;
          this.show = true;
          setTimeout(() => {
            this.show = false;
          }, 2000);
        });
      this.linkUrlPublicidadPrincipal = '';
      this.urlImagenPublicidadPrincipal = '';
    },
    guardarPublidadesSecundaria() {
      advertisementService.createAdvertisement({
        type: 'secondary',
        imageUrl: this.urlImagenPublicidadSecundaria,
        linkUrl: this.linkUrlPublicidadSecundaria,
      })
        .then((response) => {
          this.publicidadesSecundarias.push(response.data);
          this.show2 = true;
          setTimeout(() => {
            this.show2 = false;
          }, 2000);
        });
      this.urlImagenPublicidadSecundaria = '';
      this.linkUrlPublicidadSecundaria = '';
    },
    imagenPublicidadPrincipalCargada(urlImagen) {
      this.urlImagenPublicidadPrincipal = urlImagen;
    },
    imagenPublicidadSecundariaCargada(urlImagen) {
      this.urlImagenPublicidadSecundaria = urlImagen;
    },
  },
  validations() {
    return {
      linkUrlPublicidadPrincipal: {
        url,
      },
      urlImagenPublicidadPrincipal: {
        url,
      },
      linkUrlPublicidadSecundaria: {
        url,
      },
      urlImagenPublicidadSecundaria: {
        url,
      },
    };
  },
};
</script>

<style scoped>
.contenedor {
  border: black solid 1px;
  margin-top: 15px;
  padding: 10px;
}

.boton-container {
  display: inline-block;
  position: relative;
}

.boton-container div {
  position: absolute;
  top: 0;
  right: 1px;
  background-color: #8F0005;
  color: white;
  text-transform: uppercase;
}
.publicidad{
  width: 100%;
  height: auto;
}
</style>
