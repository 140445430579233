import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
});

export default {
  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    return apiClient.post('/files/upload_image', formData);
  },
};
