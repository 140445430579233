<template>
  <div class="block-area">
  </div>
  <div>
    <article
      class="card card-full hover-a py-4 post-1294 post type-post status-publish
           format-standard has-post-thumbnail hentry category-health
            tag-health tag-monitoring">
      <div class="row">
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
            <h1 class="card-title h2 h3-sm h2-md">
            </h1>
            <p class="titulo imagen" style="height: 202px"></p>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
            <h1 class="card-title h2 h3-sm h2-md">
            </h1>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
          </div>
        </div>
      </div>
    </article>
    <div class="block-title-13"><h4 class="h5 title-box-dot"></h4>
      <div class="dot-line"></div>
    </div>
    <div class="gap-0"></div>
    <article
      class="card card-full hover-a py-4 post-1294 post type-post status-publish
           format-standard has-post-thumbnail hentry category-health
            tag-health tag-monitoring">
      <div class="row">
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
            <h1 class="card-title h2 h3-sm h2-md">
            </h1>
            <p class="titulo imagen" style="height: 202px"></p>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
            <h1 class="card-title h2 h3-sm h2-md">
            </h1>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
          </div>
        </div>
      </div>
    </article>
    <div class="block-title-13"><h4 class="h5 title-box-dot"></h4>
      <div class="dot-line"></div>
    </div>
    <div class="gap-0"></div>
    <article
      class="card card-full hover-a py-4 post-1294 post type-post status-publish
           format-standard has-post-thumbnail hentry category-health
            tag-health tag-monitoring">
      <div class="row">
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
            <h1 class="card-title h2 h3-sm h2-md">
            </h1>
            <p class="titulo imagen" style="height: 202px"></p>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
            <h1 class="card-title h2 h3-sm h2-md">
            </h1>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
            <p class="titulo copete"></p>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ArticuloNoticiasEsqueleto',
};
</script>

<style scoped>
.titulo {
  width: 80%;
  margin-left: 40px;
  height: 10px;
  display: block;
  animation: pulse-bg 1s infinite;
}

.copete {
  width: 90%;
  height: 24px;
  margin-left: 40px;
  justify-content: center;
  animation: pulse-bg 1s infinite;
}

.imagen {
  width: 90%;
  height: 202px;
  margin-left: 40px;
  justify-content: center;
  animation: pulse-bg 1s infinite;
}

@keyframes pulse-bg {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #eaeaea;
  }
  100% {
    background-color: #eee;
  }
}

@media screen and (max-width: 572px) {
  .titulo {
    width: 80%;
    margin-left: 40px;
    height: 10px;
    display: block;
    animation: pulse-bg 1s infinite;
  }

  .copete {
    width: 80%;
    height: 24px;
    margin-left: 40px;
    justify-content: center;
    animation: pulse-bg 1s infinite;
  }

  .imagen {
    width: 80%;
    height: 202px;
    margin-left: 40px;
    justify-content: center;
    animation: pulse-bg 1s infinite;
  }
}
</style>
