<template>
  <Spinner v-show="mostrarSpinner"></Spinner>
  <FormularioArticulo :titulo="'Cargar noticias'" :nombreBoton="'Cargar'"
                      @formulario-articulo-cargado="guardarArticulo"/>
</template>

<script>

import FormularioArticulo from '@/components/FormularioArticulo.vue';
import newsService from '@/services/NewsService';
import Spinner from '@/components/Spinner.vue';
import alertService from '@/services/AlertService';

export default {
  name: 'CargarArticulo',
  components: {
    Spinner,
    FormularioArticulo,
  },
  data() {
    return {
      mostrarSpinner: false,
    };
  },
  methods: {
    guardarArticulo(articulo) {
      this.mostrarSpinner = true;

      newsService.createNews({
        title: articulo.titulo,
        subtitle: articulo.copete,
        imageUrl: articulo.url_imagen,
        youtubeVideoId: articulo.video,
        text: articulo.descripcion,
      })
        .then((response) => {
          if (response.status === 201) {
            this.$router.push({ name: 'NoticiasLista' });
          } else {
            console.log('Falla');
          }
        })
        .catch((error) => {
          alertService.mostrarAlerta('Error al cargar el artículo.', error);
        })
        .finally(() => {
          this.mostrarSpinner = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
