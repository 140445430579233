<template>
  <div>
    <div class="showbacktop full-nav
     border-lg-1 border-bottom shadow-b-sm border-none py-0 second-header wrapper color-menu">
      <div class="container color-menu">
        <nav id="" class="main-menu navbar navbar-expand-lg
         navbar-light px-2 px-lg-0 py-0">
          <!--Navbar menu-->
          <div id="" class="collapse navbar-collapse hover-mode">
            <!-- logo in navbar -->
            <div class="logo-showbacktop">
              <a href="" class="navbar-brand custom-logo-link"
                 rel="home"
                 aria-current="page">
                <img width="452" height="95"
                     src="./../assets/logo.png"
                     class="img-fluid"
                     srcset=""
                     sizes="(max-width: 452px) 100vw, 452px"/>
              </a>
            </div>

            <ul id="start-main" class="navbar-nav main-nav navbar-uppercase first-start-lg-0">
              <router-link to="/">
                <li itemscope="itemscope" itemtype=""
                    class="menu-item menu-item-type-custom menu-item-object-custom
                   current-menu-item current_page_item menu-item-home active
                    menu-item-1344 nav-item px-4 color_letra">
                  <a title="Home" class="nav-link">Portada</a>
                </li>
              </router-link>
              <router-link v-if="mostrarAccionesAdmin" :to="{ name: 'CargarPublicidad' }">
                <li itemscope="itemscope" itemtype=""
                    class="menu-item menu-item-type-custom menu-item-object-custom
                   current-menu-item current_page_item menu-item-home active
                    menu-item-1344 nav-item px-4 color_letra">
                  <a title="Home" class="nav-link">Publicidades</a>
                </li>
              </router-link>
              <router-link v-if="mostrarAccionesAdmin" :to="{ name: 'CargarArticulo' }">
                <li itemscope="itemscope" itemtype=""
                    class="menu-item menu-item-type-custom menu-item-object-custom
                   current-menu-item current_page_item menu-item-home active
                    menu-item-1344 nav-item px-4 color_letra">
                  <a title="Home" class="nav-link">Nuevo Articulo</a>
                </li>
              </router-link>
              <router-link v-if="mostrarAccionesAdmin" :to="{ name: 'NoticiasLista' }">
                <li itemscope="itemscope" itemtype=""
                    class="menu-item menu-item-type-custom menu-item-object-custom
                   current-menu-item current_page_item menu-item-home active
                    menu-item-1344 nav-item px-4 color_letra">
                  <a title="Home" class="nav-link">Archivo</a>
                </li>
              </router-link>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarraMenu',
  computed: {
    mostrarAccionesAdmin() {
      return localStorage.getItem('access_token') !== null;
    },
  },
};
</script>

<style scoped>
.color-menu{
  background-color: black;
}
</style>
