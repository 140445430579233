import { createRouter, createWebHistory } from 'vue-router';
import CargarPublicidad from '@/views/CargarPublicidad.vue';
import Editar from '@/views/Editar.vue';
import Home from '../views/Home.vue';
import Usuario from '../views/Usuario.vue';
import Articulo from '../views/ArticuloViews.vue';
import CargarArticulo from '../views/CargarArticulo.vue';
import NoticiasListas from '../views/NoticiasListas.vue';
import error404 from '../views/error404.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/auth/login',
    name: 'Usuario',
    component: Usuario,
  },
  {
    path: '/noticias',
    name: 'NoticiasLista',
    component: NoticiasListas,
  },
  {
    path: '/noticias/:urlId',
    name: 'Articulo',
    props: true,
    component: Articulo,
  },
  {
    path: '/noticias/new',
    name: 'CargarArticulo',
    component: CargarArticulo,
  },

  {
    path: '/publicidades/new',
    name: 'CargarPublicidad',
    component: CargarPublicidad,
  },
  {
    path: '/noticias/edit/:urlId',
    name: 'Editar',
    props: true,
    component: Editar,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error404',
    component: error404,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
