<template>
  <div v-if="publicidadesSecundarias.length!==0">
    <article v-for="publicidad in publicidadesSecundarias" :key="publicidad.imageUrl"
             class="card card-full hover-a mb-4">
      <div class=" mb-2 boton-container ">
        <a :href="publicidad.linkUrl" target="_blank" rel="noopener noreferrer">
          <img width="360" height="202"
               :src="publicidad.imageUrl"
               sizes="(max-width: 360px) 100vw, 360px"
          class="publicidad"/>
        </a>
      </div>
    </article>
  </div>
  <div v-else>
    <div class="skeletonAside"></div>
    <div class="skeletonAside"></div>
    <div class="skeletonAside"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AsidePublicidad',
  data() {
    return {
      enabled: true,
      dragging: false,
    };
  },
  computed: {
    publicidadesSecundarias() {
      return this.$store.getters.publicidadesSecundarias;
    },
  },
});

</script>

<style scoped>
.skeletonAside {
  width: 360px;
  height: 202px;
  background-color: #eaeaea;
  margin-bottom: 20px;
}

.drop-zone {
  width: 50%;
  background: red;
  min-height: 10px;
  margin: 10px;
}

.boton-container {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.boton-container div {
  position: absolute;
  top: 0;
  right: 1px;
  background-color: #8F0005;
  color: white;
  text-transform: uppercase;
}
.publicidad{
  width: 100%;
  height: auto;
}
</style>
