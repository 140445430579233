import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  login(email, password) {
    return apiClient.post('/auth/login', { email, password });
  },
};
