<template>
  <Spinner v-if="mostrarSpinner"></Spinner>
  <div class="bg-white wrapper second-header">
    <div class="container bg-white my-5 border border-primary ancho">
      <ul class="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade show active" id="pills-login"
             role="tabpanel" aria-labelledby="tab-login">
          <form @submit.prevent="iniciarSesion">
            <div class="form-outline mb-4">
              <input type="email" v-model.trim="email" class="form-control" v-model="email"/>
              <label class="form-label" for="loginName">Email</label>
              <div class="text-danger" v-if="v$.email.$invalid">Campo obligatorio</div>
            </div>

            <div class="form-outline mb-4">
              <input type="password" v-model.trim="contrasena" class="form-control"
                     v-model="contrasena"/>
              <label class="form-label" for="loginPassword">Contraseña</label>
              <div class="text-danger" v-if="v$.contrasena.$invalid">Campo obligatorio</div>
            </div>

            <div class="row mb-4">
              <div class="col-md-6 d-flex justify-content-center">
                <!-- Checkbox -->
                <div class="form-check mb-3 mb-md-0">
                  <input class="form-check-input" type="checkbox" value="" id="loginCheck" checked/>
                  <label class="form-check-label" for="loginCheck"> Recordar contraseña </label>
                </div>
              </div>

              <div class="col-md-6 d-flex justify-content-center">

                <a href="">¿Olvidaste tu contraseña?</a>
              </div>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary btn-block
             mb-4 text-center" :disabled="deshabilitarBoton">
                Iniciar sesión
              </button>
            </div>
            <div v-if="mostrarCredencialesInvalidas" class="alert alert-danger text-center"
                 role="alert">
              El usuario o contraseña son incorrectos
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { minLength, email, required } from '@vuelidate/validators';
import loginService from '@/services/LoginService';
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'Usuario',
  components: { Spinner },
  data() {
    return {
      email: '',
      contrasena: '',
      mostrarCredencialesInvalidas: false,
      mostrarSpinner: false,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      email: {
        email,
        required,
      },
      contrasena: {
        minLength: minLength(10),
        required,
      },
    };
  },
  computed: {
    deshabilitarBoton() {
      return this.v$.$invalid;
    },
  },
  methods: {
    iniciarSesion() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.mostrarSpinner = true;

      loginService.login(this.email, this.contrasena)
        .then((response) => {
          localStorage.setItem('access_token', response.data.access_token);
          window.location.href = '/';
        })
        .catch(() => {
          this.mostrarCredencialesInvalidas = true;
        })
        .finally(() => {
          this.mostrarSpinner = false;
        });
    },
  },
};
</script>

<style scoped>
.ancho {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .ancho {
    width: 70%;
  }
}
</style>
