<template>
  <article class="col-md-4 mt-4">
    <div class=" text-white overflow zoom mb-4">
      <router-link :to="{ name:'Articulo', params: {urlId:news.urlId} }">
        <div class="ratio_360-202 image-wrapper">
          <a href="">
            <img width="360" height="202"
                 :src="news.imageUrl"
                 sizes="(max-width: 360px) 100vw, 360px"/>
          </a>
        </div>
      <div class="position-absolute px-1 pt-0 b-0 w-100 bg-shadow">
        <div class="text-muted small">
          <div class="news-date text-white">
            <DateTime :date="new Date(news.createdAt)"></DateTime>
          </div>
        </div>
        <h3 class="h3 h5-sm h3-md">
          <p class="text-white h4 h6-md h4-lg">{{news.title}}</p>
        </h3>
      </div>
      </router-link>
    </div>
  </article>
</template>

<script>
import DateTime from './DateTime.vue';

export default {
  name: 'NoticiaCarrusel',
  components: { DateTime },
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  img {
    width: 100%;
    height: 380px;
  }
}
.image-wrapper{
  border: 1px solid darkgray;
}
@media screen and (max-width: 490px) {
  img {
    width: 100%;
    height: 250px;
  }
}
</style>
