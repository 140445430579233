<template>
  <div>
    <Spinner v-show="mostrarSpinner"></Spinner>
    <div class="form-outline">
      <label class="form-label" for="cargaImagen">Imagen</label>
      <input id="cargaImagen" type="file" class="form-control" accept="image/*"
             @change="subirImagen"/>
    </div>
  </div>
</template>

<script>
import fileService from '@/services/ImageUploadingService';
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'CargadorImagen',
  components: { Spinner },
  data() {
    return {
      mostrarSpinner: false,
    };
  },
  methods: {
    subirImagen(event) {
      const file = event.target.files[0] || event.dataTransfer.files[0];

      this.mostrarSpinner = true;

      fileService.uploadFile(file)
        .then((response) => {
          if (response.status === 201) {
            this.$emit('imagen-cargada', response.data.imageUrl);
          } else {
            this.pedirUrlImagenAUsuario();
          }
        }).catch(() => {
          this.pedirUrlImagenAUsuario();
        }).finally(() => {
          this.mostrarSpinner = false;
        });
    },
    pedirUrlImagenAUsuario() {
      // eslint-disable-next-line
      const urlImagen = prompt('Ocurrió un error al subir la imagen. Por favor, suba la imagen a un servidor, y copie la URL en este campo:');
      this.$emit('imagen-cargada', urlImagen);
    },
  },
};
</script>

<style scoped>

</style>
