<template>
  <div class="bg-white wrapper second-header articulo">
    <div class="container text-center articuloCentro">
      <div class="row">
        <div class="col-md-8">
          <article>
            <div v-if="news">
              <p>
                <DateTime class="fecha" :date="dateTime"></DateTime>
              </p>
              <div>
                <h1 class="py-2 titulo "><b>{{ news.title }}</b></h1>
              </div>
              <p class="subtitulo">{{ news.subtitle }} </p>
              <div>
                <img :src="news.imageUrl"
                     class="imagen"/>
              </div>
              <p class="wrapper parrafo "
                 v-for="paragraph in newsText" :key="paragraph"> {{ paragraph }}</p>
            </div>
            <div class="my-4" v-if="news.youtubeVideoId.length!==0">
              <iframe width="560" height="315"
                      class="video"
                      :src="`https://www.youtube.com/embed/${news.youtubeVideoId}`"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write;
                       encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>
              </iframe>
            </div>
            <br/>
            <BotonCompartirFacebook></BotonCompartirFacebook>
            <br/>
          </article>
        </div>
        <div class="col-md-4">
          <Aside/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BotonCompartirFacebook from '@/components/BotonCompartirFacebook.vue';
import DateTime from './DateTime.vue';
import Aside from './Aside.vue';

export default {
  components: {
    BotonCompartirFacebook,
    Aside,
    DateTime,
  },
  props: {
    news: {
      required: true,
      type: Object,
    },
  },
  computed: {
    newsText() {
      return this.news.text.split('\n');
    },
    dateTime() {
      return new Date(this.news.createdAt);
    },
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
}
.articuloCentro {
  width: 95%;
}

.fecha {
  text-align: left;
  margin-top: 0;
  margin-left: 35px;
}

p {
  text-align: center;
}

.parrafo {
  text-align: left;
  width: 90%;
  line-height: 40px;
  font-size: 1.1rem;
  word-wrap: break-word;
  margin: 0;
}
.subtitulo{
  text-align: left;
  width: 90%;
  line-height: 40px;
  font-size: 1.5rem;
  word-wrap: break-word;
  color: rgb(136, 136, 136);
}

.imagen {
  width: 90%;
  height: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.titulo {
  line-height: 40px;
  font-weight: bold;
  text-align: justify;
  width: 90%;
  font-size: 1.9em;
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .video{
    width: 100%;
  }
  .parrafo {
    text-align: left;
    width: 100%;
    line-height: 30px;
    font-size: 0.9rem;
    word-wrap: break-word;
  }
  .subtitulo{
    text-align: left;
    width: 100%;
    line-height: 30px;
    font-size: 1rem;
    word-wrap: break-word;
  }

  .titulo {
    line-height: 30px;
    font-weight: bold;
    text-align: justify;
    width: 100%;
    font-size: 1.3em;
    word-wrap: break-word;
  }
  @media screen and (max-width: 550px) {
    .subtitulo {
      line-height: 30px;
    }
  }
}
</style>
