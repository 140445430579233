<template>
  <div class="bg-white wrapper second-header articulo py-4">
    <div class="container text-center articuloCentro">
      <div class="row">
        <div class="col-8">
          <article>
            <div>
                <h1 class=" titulo py-4"></h1>
              <h4 class="copete"/>
              <div>
                <div class="imagen"/>
              </div>
              <p class="wrapper parrafo"/>
              <p class="copete"/>
              <p class="copete"/>
              <p class="copete"/>
              <p class="copete"/>
              <p class="copete"/>
              <p class="copete"/>
            </div>
          </article>
        </div>
        <div class="col-4">
          <Aside/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Aside from '@/components/Aside.vue';

export default {
  name: 'ArticuloEsqueleto',
  components: {
    Aside,
  },
};
</script>

<style scoped>

.imagen{
  width: 90%;
  height: 250px;
  margin-left: 40px;
  justify-content: center;
  animation: pulse-bg 1s infinite;
}

.titulo {
   width: 90%;
   margin-left: 40px;
   height: 10px;
   display: block;
   animation: pulse-bg 1s infinite;
 }

.copete {
  width: 90%;
  height: 24px;
  margin-left: 40px;
  justify-content: center;
  animation: pulse-bg 1s infinite;
}

@keyframes pulse-bg {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #eaeaea;
  }
  100% {
    background-color: #eee;
  }
}
</style>
