<template>
  <footer>
    <div class="footer-copyright bg-secondary">
      <div class="container">
        <!--Navbar-->
        <div class="navbar navbar-expand navbar-dark px-0 text-center"
             style="justify-content: center">
          <div class=" navbar-text"> Copyright © {{ year }} - Todos los derechos
            reservados <strong>Diario Colonia Marina.</strong></div>
          <div class="card-text mb-2 text-muted small">
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>

export default {
  name: 'Footer',
  computed: {
    year() {
      return `${(new Date()).getFullYear()}`;
    },
  },
};
</script>

<style scoped>
.footer-copyright {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 570px) {
  div{
    margin: auto;
    justify-content: center;
  }
}
</style>
