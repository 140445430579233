import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
});

export default {
  getAdvertisements() {
    return apiClient.get('/advertisement');
  },
  createAdvertisement(data) {
    return apiClient.post('/advertisement', data);
  },
  deleteAdvertisement(id) {
    return apiClient.delete(`/advertisement/${id}`);
  },
};
