<template>
  <NavbarMobile/>
  <BarraMenu/>
  <router-view/>
  <Footer2/>
  <Footer/>

</template>

<script>
import NavbarMobile from './components/NavbarMobile.vue';
import BarraMenu from './components/BarraMenu.vue';
import Footer from './components/Footer.vue';
import Footer2 from './components/Footer2.vue';

export default {
  components: {
    Footer2,
    Footer,
    BarraMenu,
    NavbarMobile,
  },
  created() {
    this.$store.dispatch('cargarPublicidades');
  },
};

</script>

<style>
.primary {
  color: #1e73be;
}

.font-family h1, .font-family h2, .font-family h3, .font-family h4, .font-family h5,
.font-family h6, .font-family .h1, .font-family .h2, .font-family .h3, .font-family .h4,
.font-family .h5, .font-family .h6, .font-family .navbar-nav, .menu-mobile a,
.u-breadcrumb .breadcrumb-item, .post-content .tags-list li:first-child {
  font-family: "Roboto", sans-serif;
}

body {
  font-family: "Open Sans", sans-serif;
}

.full-width {
  background: #ffffff !important;
}

.dark-skin.full-width {
  background: #1b1b1b !important;
}

.bg-primary, .progress-bar, .badge-primary {
  background-color: #1e73be !important;
}

.center-backline:before, .center-backline-lg:before, .title-arrow:before {
  background-color: #1e73be !important;
}

.box-title:before {
  border-top-color: #1e73be;
}

.badge-primary {
  color: #ffffff !important;
}

.fa-stack-sea {
  border: #1e73be 3px solid;
}

.form-control:focus {
  border-color: #1e73be;
}

.hover-a:hover .fa-play-circle {
  background: #1e73be;
  border: #1e73be 2px solid;
}

.page-link, .btn-link {
  color: #1e73be;
}

a:hover {
  color: #1e73be;
}

h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover, .h1 > a:hover,
.h2 > a:hover, .h3 > a:hover, .h4 > a:hover, .h5 > a:hover, .h6 > a:hover {
  color: #1e73be;
}

.hover-a:hover h1 > a, .hover-a:hover h2 > a, .hover-a:hover h3 > a, .hover-a:hover h4 > a,
.hover-a:hover h5 > a, .hover-a:hover h6 > a, .hover-a:hover .h1 > a, .hover-a:hover .h2 > a,
.hover-a:hover .h3 > a, .hover-a:hover .h4 > a, .hover-a:hover .h5 > a, .hover-a:hover .h6 > a,
.hover-a:hover p > a, .prev-post-start a:hover, .next-post-end a:hover {
  color: #1e73be;
}

.nav-tabs .nav-link.active, .sidenav-menu .dropdown a.show {
  color: darkorange;
}

.nav-tabs .nav-item.show .nav-link {
  color: #1e73be;
}

.navbar-nav .dropdown-item:hover, #main-menu .vertical-tabs .nav-tabs .nav-link.active,
.dropdown-item.active, .dropdown-item:active, a {
  color: #1e73be;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus,
.navbar-nav .dropdown-item:hover, .text-primary {
  color: #fd621d !important;
  font-size: 18px;
  font-weight: bold;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link, .custom-range::-ms-thumb,
.custom-range::-moz-range-thumb, .custom-range::-webkit-slider-thumb, .custom-radio
.custom-control-input:checked ~ .custom-control-label::before, .custom-checkbox
.custom-control-input:indeterminate ~ .custom-control-label::before, .custom-checkbox
.custom-control-input:checked ~ .custom-control-label::before, .custom-control-input:checked
~ .custom-control-label::before, .dropdown-item.active, .dropdown-item:active {
  background-color: #1e73be;
}

.dropdown-menu.mega .container, .dropdown-menu, .border-primary {
  border-color: #1e73be !important;
}

.list-group-item.active, .btn-outline-primary {
  color: #1e73be;
  border-color: #1e73be;
}

.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active, .show >
.btn-outline-primary.dropdown-toggle, .page-link:hover, .page-item.active .page-link,
.btn-primary, .btn-outline-primary:hover, .btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: #1e73be;
  border-color: #1e73be;
}

.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary:hover {
  opacity: .98;
}

.page-link:hover {
  color: #fff;
}

.big-nav .list-group-item.active {
  color: #ffffff;
  background-color: #1e73be;
  border-color: #1e73be;
}

.big-nav.carousel-indicators li:hover {
  background-color: #1e73be;
  color: #ffffff;
}

.hover-a li:hover a {
  color: #1e73be;
}

.hover-a:hover a.h1, .hover-a:hover a.h2, .hover-a:hover a.h3, .hover-a:hover a.h4,
.hover-a:hover a.h5, .hover-a:hover a.h6 {
  color: #1e73be;
}

li:hover a.h5 {
  color: #1e73be;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #1e73be;
  border-color: #1e73be;
}

.sidetabs .nav-link.active:hover {
  border-color: #1e73be;
}

.card-list li:hover a {
  color: #1e73be;
}

.nav-block-link li a.nav-link.active, .nav-block-link1 li a.nav-link.active, .nav-block-link2 li
a.nav-link.active, .nav-block-link3 li a.nav-link.active, .nav-block-link4 li a.nav-link.active,
.nav-block-link5 li a.nav-link.active, .nav-block-link6 li a.nav-link.active, .nav-block-link7
li a.nav-link.active, .nav-block-more li a.nav-link.active {
  color: #1e73be !important;
  background-color: transparent;
}

.nav-block-link li a.nav-link.active, .nav-block-link li a.nav-link:hover, .nav-block-link1 li
a.nav-link.active, .nav-block-link1 li a.nav-link:hover, .nav-block-link2 li a.nav-link.active,
.nav-block-link2 li a.nav-link:hover, .nav-block-link3 li a.nav-link.active, .nav-block-link3 li
a.nav-link:hover, .nav-block-link4 li a.nav-link.active, .nav-block-link4 li a.nav-link:hover,
.nav-block-link5 li a.nav-link.active, .nav-block-link5 li a.nav-link:hover, .nav-block-link6 li
a.nav-link.active, .nav-block-link6 li a.nav-link:hover, .nav-block-link7 li a.nav-link.active,
.nav-block-link7 li a.nav-link:hover, .nav-block-more li a.nav-link.active, .nav-block-more li
a.nav-link:hover {
  color: #1e73be;
}

.widget ol li a:hover, .widget ul li a:hover {
  color: #1e73be;
}

.owl-carousel button.owl-dot.active {
  background: #1e73be;
}

.suggestion-box {
  border-top: 4px solid #1e73be;
}

.side-link li a:hover, .side-link li a.active, .sidebar-link li a:hover, .sidebar-link li a.active {
  background-color: transparent;
  color: #1e73be;
}

.side-link li ul li a:hover, .sidebar-link li ul li a:hover {
  color: #1e73be;
}

.side-link li a.caret-collapse, .sidebar-link li a.caret-collapse {
  color: #1e73be;
}

.side-link li a.caret-collapse.collapsed:hover, .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #1e73be;
}

.post-number li:hover a::before {
  color: #1e73be !important;
}

.timeline-post > li a .timeline-date:hover, .timeline-post > li a .timeline-title:hover {
  color: #1e73be !important;
}

.hover-mode .main-nav li:hover > a {
  color: #1e73be;
}

.sidenav-menu li.show > a.dropdown-toggle, .navbar-light .nav-item:hover > .nav-link {
  color: #1e73be;
}

.timeline-post > li:hover:before {
  background: #1e73be !important;
}

.side-link li a.caret-collapse.collapsed, .sidebar-link li a.caret-collapse.collapsed {
  color: #161616;
}

.bg-secondary {
  background-color: #000000 !important;
}

.main-menu.navbar-dark .navbar-nav > .nav-item.active, .main-menu.navbar-dark
.navbar-nav > .nav-item:hover {
  background: #1e73be !important;
}

.btn-secondary {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.btn-secondary:hover {
  background-color: #000000;
  border-color: #000000;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #000000;
  border-color: #000000;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #000000;
  border-color: #000000;
}

.btn-outline-secondary {
  color: #000000;
  border-color: #000000;
}

.btn-outline-secondary:hover {
  background-color: #000000;
  border-color: #000000;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #000000;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: #000000;
  border-color: #000000;
}

.badge-secondary {
  color: #ffffff;
  background-color: #000000;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #ffffff;
  background-color: #000000;
}

.alert-secondary .alert-link {
  color: #000000;
}

.list-group-item-secondary {
  color: #000000;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #000000;
}

.list-group-item-secondary.list-group-item-action.active {
  background-color: #000000;
  border-color: #000000;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #000000 !important;
}

.border-secondary {
  border-color: #000000 !important;
}

.text-secondary {
  color: #000000 !important;
}

.main-menu.navbar-dark .hover-a:hover .h1 > a, .main-menu.navbar-dark .hover-a:hover .h2 > a,
.main-menu.navbar-dark .hover-a:hover .h3 > a, .main-menu.navbar-dark .hover-a:hover .h4 > a,
.main-menu.navbar-dark .hover-a:hover .h5 > a, .main-menu.navbar-dark .hover-a:hover .h6 > a,
.main-menu.navbar-dark .hover-a:hover h1 > a, .main-menu.navbar-dark .hover-a:hover h2 > a,
.main-menu.navbar-dark .hover-a:hover h3 > a, .main-menu.navbar-dark .hover-a:hover h4 > a,
.main-menu.navbar-dark .hover-a:hover h5 > a, .main-menu.navbar-dark .hover-a:hover h6 > a {
  color: #1e73be !important;
}
a.text-secondary:hover, a.text-secondary:focus {
  color: #000000 !important;
}
.main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active,
.main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus,
.main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
  color: #1e73be !important;
}
.dark-skin .hover-a:hover .h1 > a, .dark-skin .hover-a:hover .h2 >
a, .dark-skin .hover-a:hover .h3 > a,
.dark-skin .hover-a:hover .h4 > a, .dark-skin .hover-a:hover .h5 >
a, .dark-skin .hover-a:hover .h6 > a,
.dark-skin .hover-a:hover h1 > a, .dark-skin .hover-a:hover h2 >
a, .dark-skin .hover-a:hover h3 > a,
.dark-skin .hover-a:hover h4 > a, .dark-skin .hover-a:hover h5 >
a, .dark-skin .hover-a:hover h6 > a {
  color: #1e73be !important;
}
.dark-skin .side-link li a.active, .dark-skin .side-link li a.caret-collapse.collapsed.active,
.dark-skin .side-link li a.caret-collapse.collapsed:hover, .dark-skin .side-link li a:hover,
.dark-skin .sidebar-link li a.active, .dark-skin .sidebar-link li a.caret-collapse.collapsed.active,
.dark-skin .sidebar-link li a.caret-collapse.collapsed:hover, .dark-skin .sidebar-link li a:hover {
  color: #1e73be;
}
.next-post-right a:hover, .prev-post-left a:hover, .footer-dark a:hover, .layout-dark a:hover,
.dark-skin .next-post-right > a:hover, .dark-skin .prev-post-left >
a:hover, .footer-dark a.btn:hover,
.dark-skin a.btn-outline-light:hover strong {
  color: #1e73be;
}

.footer-dark .widget ul li a:hover, .footer-dark .widget ol li a:hover {
  color: #1e73be;
}

.btn-primary.disabled, .btn-primary:disabled, .btn-primary:hover {
  background-color: #1e73be;
  border-color: #1e73be;
}

.post-page blockquote, .u-blockquote, .wp-block-quote {
  border-left: 3px solid #1e73be;
}

.tags-links a:hover {
  background-color: #1e73be;
}

.bg-custom-footer {
  background-color: #31b1f9;
}

#showbacktop.bg-navcustom {
  background: #31b1f9 !important;
}

.main-menu.nav-custom.navbar-dark .dropdown-menu h1 > a, .main-menu.nav-custom.navbar-dark
.dropdown-menu h2 > a, .main-menu.nav-custom.navbar-dark .dropdown-menu h3 > a,
.main-menu.nav-custom.navbar-dark h4 > a, .main-menu.nav-custom.navbar-dark h5 > a,
.main-menu.nav-custom.navbar-dark .dropdown-menu h6 > a, .main-menu.nav-custom.navbar-dark
.dropdown-menu .h1 > a, .main-menu.nav-custom.navbar-dark .dropdown-menu .h2 > a,
.main-menu.nav-custom.navbar-dark .dropdown-menu .h3 > a, .main-menu.nav-custom.navbar-dark
.dropdown-menu .h4 > a, .main-menu.nav-custom.navbar-dark .dropdown-menu .h5 > a,
.main-menu.nav-custom.navbar-dark .dropdown-menu .h6 > a {
  color: #23282d !important;
}

.main-menu.nav-custom.navbar-dark a.dropdown-item {
  color: #23282d !important;
}

.main-menu.nav-custom.navbar-dark .navbar-nav .nav-item .dropdown-menu {
  background-color: #fff;
}

.main-menu.nav-custom.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover,
.main-menu.nav-custom.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus,
.main-menu.nav-custom.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  background: rgb(255, 255, 255);
}

.nav-custom.navbar-dark .vertical-tabs {
  background: #f8f8f8;
  border-color: #f8f8f8;
}

.nav-custom.main-menu.navbar-dark .navbar-nav .vertical-tabs .nav-tabs .nav-link {
  border-color: #f8f8f8;
}

.nav-custom.main-menu.navbar-dark .vertical-tabs a {
  color: #495057 !important;
}

.main-menu.nav-custom.navbar-dark .navbar-nav .vertical-tabs .nav-tabs .nav-link.active,
.main-menu.nav-custom.navbar-dark .navbar-nav .vertical-tabs .nav-tabs .nav-link:hover {
  border-color: #fff;
  background-color: #fff !important;
}

.bg-navcustom {
  background: #31b1f9 !important;
}

.taxonomy-description {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  text-align: center;
}

.mobile-nav-custom img {
  max-width: 200px
}

.wp-block-embed.is-type-video {
  width: 100%;
}

.wp-block-embed iframe {
  max-width: 100%;
}

.wp-block-table table {
  border: #dee2e6 1px solid;
}

.wp-block-table table tr, .wp-block-table table th, .wp-block-table table td {
  border: #dee2e6 1px solid;
}

.post-content img {
  height: auto;
}

@media (min-width: 992px) {
  #showbacktop.bg-navcustom.is-visible {
    background: #31b1f9 !important;
  }

  .nav-custom.navbar-dark .hover-mode li:hover > a {
    background: #fff !important;
  }

  .mobile-nav-custom, .mobile-nav-custom img {
    max-width: 0;
    display: none;
  }

  .post-content .wp-block-embed {
    padding-bottom: 46.25%;
  }
}

@media (min-width: 992px) {
  .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a, .main-menu.navbar-dark .hover-mode
  .main-nav > li > a.active {
    background-color: #1e73be !important;
  }

  .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #1e73be !important;
  }

  .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #fff !important;
  }

  .logo-full .main-logo img {
    max-width: 25rem;
  }

  .search-top-right, .social-top-left {
    top: 50%;
    margin-top: -23px;
    position: absolute;
  }

  #showbacktop .logo-showbacktop.still-show {
    display: block;
  }

  #showbacktop .logo-showbacktop.still-show img {
    max-height: 2.3rem;
    margin-right: 1rem;
    display: block;
  }

  #showbacktop.is-visible .logo-showbacktop.still-show {
    display: none;
    height: 0;
  }
}

@media (min-width: 768px) {
  .grid-six .image {
    width: 70%;
  }

  .grid-six .caption {
    position: absolute;
    width: 50%;
    top: 50%;
    right: 0;
    padding: 2rem;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.six-box-box {
  padding-left: .875rem;
  padding-right: .875rem
}

@media (max-width: 991.98px) {
  .admin-bar .mobile-sticky.is-sticky {
    top: 30px;
    z-index: 1000;
  }
}

@media (max-width: 400px) {
  .top-space {
    top: 20%;
  }
}

.fondo {
  background-image: url("https://demo.bootstrap.news/blog/wp-content/uploads/2019/06/bgg-1.png");
}
.fondoMenu{
  background: linear-gradient(to right, #efefef, white );
  margin: 0;
  padding: 0;
 }
</style>
