<template>
  <Spinner v-show="mostrarSpinner"></Spinner>
  <FormularioArticulo v-if="this.news !== null" :titulo="'Editar articulo'"
                      :nombreBoton="'Editar'" :articulo-inicial="news"
                      @formulario-articulo-cargado="guardarArticulo"/>
</template>

<script>

import FormularioArticulo from '@/components/FormularioArticulo.vue';
import newsService from '@/services/NewsService';
import Spinner from '@/components/Spinner.vue';
import alertService from '@/services/AlertService';

export default {
  name: 'Editar',
  components: {
    Spinner,
    FormularioArticulo,
  },
  props: {
    urlId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      news: null,
      mostrarSpinner: false,
    };
  },
  methods: {
    guardarArticulo(articulo) {
      this.mostrarSpinner = true;

      newsService.editSpecificNews(this.news.id, {
        title: articulo.titulo,
        subtitle: articulo.copete,
        imageUrl: articulo.url_imagen,
        youtubeVideoId: articulo.video,
        text: articulo.descripcion,
      })
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: 'NoticiasLista' });
          } else {
            console.log('Falla');
          }
        })
        .catch((error) => {
          alertService.mostrarAlerta('Error al editar el artículo.', error);
        })
        .finally(() => {
          this.mostrarSpinner = false;
        });
    },
  },
  created() {
    newsService.getSpecificNews(this.urlId)
      .then((response) => {
        this.news = response.data;
      });
  },
};
</script>

<style scoped>

</style>
