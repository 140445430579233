<template>
  <div class="wrapper">
    <header class="header">
      <div class="top-menu bg-white">
        <div class="container">
          <!-- PRIMER Navbar celular-->
          <nav class="navbar navbar-expand d-lg-none navbar-light px-0">
            <div id="navbar-mobile" class="collapse navbar-collapse nav-top-mobile">
              <!--iconos REDES SOCIALES navbar celular-->
              <ul class="navbar-nav ms-auto text-center">
                <li class="nav-item">
                  <a class="nav-link" rel="noopener"
                     href="https://www.facebook.com/Diario-Colonia-Marina-101703771484849"
                     target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px"
                         fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0
                       4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017
                        1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993
                         0-1.303.621-1.303 1.258v1.51h2.218l-.354
                          2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                    </svg>
                    <span class="visually-hidden">Facebook</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" rel="noopener" href="https://wa.me/5493564501098"
                     target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px"
                         fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                      <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0
                       .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0
                        16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926
                        -3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994
                         14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666
                         -2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957
                         -6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557
                          0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615
                          -4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099
                          -.445.099-.133.197-.513.646-.627.775-.114.133-.232.148
                          -.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985
                          -1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087
                          -.088.197-.232.296-.346.1-.114.133-.198.198-.33.065
                          -.134.034-.248-.015-.347-.05-.099-.445-1.076-.612
                          -1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247
                          -.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198
                          -.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394
                           2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38
                           -.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182
                           -.133-.38-.232z"/>
                    </svg>
                    <span class="visually-hidden">WhatsApp</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" rel="noopener" href="https://youtube.com" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px"
                         fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033
                       6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22
                        1.402l.01.104.022.26.008.104c.065.914.073 1.77.074
                         1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c
                         -.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c
                         -1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927
                         -.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167
                         -.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417
                         -.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v
                         -.122C.002 7.343.01 6.6.064 5.78l.007-.103.003-.052.008-.104.022
                         -.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415
                         -1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171
                         -.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157
                         -2.408L6.4 5.209z"/>
                    </svg>
                    <span class="visually-hidden">Youtube</span>
                  </a>
                </li>
                <div class="bg-image"></div>
                <li class="nav-item">
                  <a class="nav-link" rel="noopener"
                     href="https://www.instagram.com/walter.montoya.tv/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px"
                         fill="currentColor" viewBox="0 0 512 512">
                      <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62
                        ,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62
                        ,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32
                        ,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480
                        ,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z"/>
                      <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0
                        ,1,377.33,162.67Z"/>
                      <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1
                        ,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z"/>
                    </svg>
                    <span class="visually-hidden">Instagram</span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <!-- barra de menu celular/SEGUNDO NAVBAR CELULAR -->
      <div class="mobile-sticky fs-6 bg-secondary">
        <div class="container">
          <nav class="navbar navbar-expand-lg navbar-dark px-0 py-0">
            <a id="showStartPush" aria-label="sidebar menu"
               class="navbar-toggler sidebar-menu-trigger side-hamburger border-0 px-0"
               href="">
            <div class="dropdown" v-if="mostrarAccionesAdmin">
              <a class="btn btn-secondary dropdown-toggle" href="#" role="button"
                 id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                     fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5
                  -.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5
                  -.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5
                  -.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <router-link to="/">
                <li><a class="dropdown-item" href="#">Portada</a></li>
                </router-link>
                <router-link :to="{ name: 'CargarPublicidad' }">
                <li><a class="dropdown-item" href="#">Publicidades</a></li>
                </router-link>
                <router-link :to="{ name: 'CargarArticulo' }">
                <li><a class="dropdown-item" href="#">Nuevo articulo</a></li>
                </router-link>
                <router-link :to="{ name: 'NoticiasLista' }">
                <li><a class="dropdown-item" href="#">Archivo</a></li>
                </router-link>
              </ul>
            </div>
            </a>
            <router-link :to="{name: 'Home'}">
              <!-- logo DIARIO en la barra negra -->

              <img class="mobile-logo img-fluid d-lg-none mx-auto center" alt="mobile logo"
                   src="./../assets/logo_diario_colonia_marina(2).png">
            </router-link>
            <!-- PRIMER NAVBAR  EN PC-->
            <div id="navbarTogglerDemo" class="collapse navbar-collapse">
              <!-- dia y hora -->
              <div class="date-area d-none d-lg-block">
                <time class="navbar-text me-2" datetime="2022-06-21">
                  <b>{{ currentDateTime }}</b>
                </time>
              </div>
              <!--ICONOS REDES SOCIALES PC-->
              <ul class="navbar-nav ms-auto text-center">
                <li class="nav-item">
                  <a class="nav-link" rel="noopener"
                     href="https://www.facebook.com/Diario-Colonia-Marina-101703771484849"
                     target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px"
                         fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16 ">
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0
                       4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017
                        1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993
                         0-1.303.621-1.303 1.258v1.51h2.218l-.354
                          2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                    </svg>
                    <span class="visually-hidden">Facebook</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" rel="noopener" href="https://wa.me/5493564501098"
                     target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px"
                         fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                      <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0
                       .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0
                        16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926
                        -3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994
                         14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666
                         -2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957
                         -6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557
                          0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615
                          -4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099
                          -.445.099-.133.197-.513.646-.627.775-.114.133-.232.148
                          -.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985
                          -1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087
                          -.088.197-.232.296-.346.1-.114.133-.198.198-.33.065
                          -.134.034-.248-.015-.347-.05-.099-.445-1.076-.612
                          -1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247
                          -.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198
                          -.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394
                           2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38
                           -.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182
                           -.133-.38-.232z"/>
                    </svg>
                    <span class="visually-hidden">WhatsApp</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" rel="noopener" href="https://youtube.com" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px"
                         fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033
                       6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22
                        1.402l.01.104.022.26.008.104c.065.914.073 1.77.074
                         1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c
                         -.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c
                         -1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927
                         -.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167
                         -.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417
                         -.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v
                         -.122C.002 7.343.01 6.6.064 5.78l.007-.103.003-.052.008-.104.022
                         -.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415
                         -1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171
                         -.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157
                         -2.408L6.4 5.209z"/>
                    </svg>
                    <span class="visually-hidden">Youtube</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" rel="noopener"
                     href="https://www.instagram.com/walter.montoya.tv/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px"
                         fill="currentColor" viewBox="0 0 512 512">
                      <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62
                        ,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62
                        ,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32
                        ,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480
                        ,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z"/>
                      <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0
                        ,1,377.33,162.67Z"/>
                      <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1
                        ,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z"/>
                    </svg>
                    <span class="visually-hidden">Instagram</span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <!--SEGUNDO NAVBAR-->
      <!--LOGO Y PUBLICIDAD PC-->
      <div class="second-header">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <!-- LOGO -->
              <div id="" class=" logo_ main-logo my-2 my-lg-4 d-none d-lg-block
               text-center-md-down mx-5">
                <router-link :to="{name: 'Home'}">
                  <img src="./../assets/logo_diario_colonia_marina.png"/>
                </router-link>
              </div>
            </div>
            <!--PUBLICIDAD RECTANGULAR-->
            <div class="col-lg-8 mt-4">
              <div id="custom_html-1" class="widget_text my-2 my-md-3 my-lg-4
               d-md-block text-center">
                <div v-if="publicidadPrincipal" class="textwidget custom-html-widget">
                  <a
                    :href="publicidadPrincipal.linkUrl"
                    target="_blank" rel="noopener">
                    <img class=" ratio_628-120 image-wrappe bg-light "
                         :src="publicidadPrincipal.imageUrl"
                         alt="Advertiser space"
                         width="628" height="120"
                         sizes="(max-width: 728px) 100vw, 728px"/>
                  </a>
                </div>
                <div class="skeletonAdvMain" v-else></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>

</template>

<script>
export default {
  name: 'NavbarMobile',
  computed: {
    currentDateTime() {
      const now = new Date();
      // eslint-disable-next-line
      let dateTime = `${now.toLocaleString('es-ar', { weekday: 'long' })} ${(now.getDate()).toString()
        .padStart(2, '0')} de ${now.toLocaleString('es-ar', { month: 'long' })} de ${now.getFullYear()} - ${now.getHours()
        .toString()
        .padStart(2, '0')}:${now.getMinutes()
        .toString()
        .padStart(2, '0')}`;
      return dateTime[0].toUpperCase() + dateTime.slice(1);
    },
    publicidadPrincipal() {
      return this.$store.getters.publicidadPrincipal;
    },
    mostrarAccionesAdmin() {
      return localStorage.getItem('access_token') !== null;
    },
  },
};
</script>

<style scoped>
.skeletonAdvMain {
  width: 728px;
  height: 90px;
  background-color: #eaeaea;
}

@media screen and (max-width: 1200px) {
  logo_ {
    max-width: 10.875rem;
  }
}

@media screen and (max-width: 1110px) {
  .ratio_628-120 {
    width: 95%;
    height: auto;
  }
}

@media screen and (max-width: 992px) {
  .ratio_628-120 {
    width: 95%;
    height: auto;
  }
}

</style>
