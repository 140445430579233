<template>
<div class="contenedor bg-white wrapper second-header">
  <div class="">
    <h1 class="texto" style="font-size: 50px">¡Oops!</h1>
    <h1 class="texto"><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80"
                           fill="currentColor" class="bi bi-emoji-frown-fill"
                           viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s
      -1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm-2.715 5.933a.5.5 0 0 1-.183
      -.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1
      -.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1
      -.683.183zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"/>
    </svg></h1>
    <h2 class="texto">¡Tenemos un problema!</h2>
    <h2 class="texto">Error 404 - Página no encontrada.</h2>
      <h2 class="texto"><svg xmlns="http://www.w3.org/2000/svg"
                                                             width="50" height="50"
                                                             fill="currentColor"
                                                             class="bi bi-search"
                                                             viewBox="0 0 16 16">
      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h
      -.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415
      -1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1
      -11 0 5.5 5.5 0 0 1 11 0z"/>
    </svg></h2>
  </div>
</div>
</template>

<script>
export default {
  name: 'error404',
};
</script>

<style scoped>
.texto{
  color: black;
  align-items: center;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.contenedor{
  height: 600px;
}
</style>
