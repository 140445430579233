<template>
  <time :datetime="datetimeAttribute">
    <div>
      {{ dateText }}
    </div>
  </time>
</template>

<script>
export default {
  name: 'DateTime',
  props: {
    date: {
      required: true,
      type: Date,
    },
    showOnlyDate: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    dateText() {
      let date = `${(this.date.getDate()).toString().padStart(2, '0')}/${(this.date.getMonth() + 1).toString().padStart(2, '0')}/${this.date.getFullYear()}`;
      if (!this.showOnlyDate) {
        date += ` ${this.date.getHours().toString().padStart(2, '0')}:${this.date.getMinutes().toString().padStart(2, '0')}`;
      }

      return date;
    },
    datetimeAttribute() {
      return `${this.date.getFullYear()}/${(this.date.getMonth() + 1).toString().padStart(2, '0')}/${(this.date.getDate()).toString().padStart(2, '0')}T${this.date.getHours().toString().padStart(2, '0')}:${this.date.getMinutes().toString().padStart(2, '0')}`;
    },
  },
};
</script>

<style scoped>

</style>
