<template>
  <div>
    <Articulo v-if="news" :news="news"/>
    <ArticuloEsqueleto v-else/>
  </div>
</template>

<script>

import Articulo from '@/components/Articulo.vue';
import ArticuloEsqueleto from '@/components/ArticuloEsqueleto.vue';
import newsService from '@/services/NewsService';

export default {
  name: 'ArticuloViews',
  components: {
    ArticuloEsqueleto,
    Articulo,
  },
  props: {
    urlId: {
      required: true,
      type: String,
    },
  },
  created() {
    newsService.getSpecificNews(this.urlId)
      .then((response) => {
        this.news = response.data;
      })
      .catch((error) => console.log(error));
  },
  data() {
    return {
      news: null,
    };
  },
};

</script>
<style scoped>
</style>
