<template>
  <Carrusel v-if="noticiasCarrusel.length !== 0" :news="noticiasCarrusel"/>
  <CarruselEsqueleto v-else/>
  <header class="header">
    <div class="bg-white wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="block-title-13"><h4 class="h3 title-box-dot"><span
              class="letras"></span></h4>
              <div class="dot-line"></div>
            </div>
            <ArticuloNoticiasEsqueleto v-if="noticiasSecundarias.length === 0"/>
            <ArchivoNoticias v-else v-for="news in noticiasSecundarias"
                             :key="news.urlId" :news="news"/>
            <InfiniteLoading @infinite="cargarNoticiasPortadas">
              <template #complete>
                <span></span>
              </template>
            </InfiniteLoading>
          </div>

          <div class="col-md-4">
            <Aside/>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import CarruselEsqueleto from '@/components/CarruselEsqueleto.vue';
import ArticuloNoticiasEsqueleto from '@/components/ArticuloNoticiasEsqueleto.vue';
import newsService from '@/services/NewsService';
import InfiniteLoading from 'v3-infinite-loading';
import Carrusel from '../components/Carrusel.vue';
import ArchivoNoticias from '../components/ArchivoNoticias.vue';
import Aside from '../components/Aside.vue';
import 'v3-infinite-loading/lib/style.css';

export default {
  name: 'Home',
  components: {
    ArticuloNoticiasEsqueleto,
    CarruselEsqueleto,
    ArchivoNoticias,
    Carrusel,
    Aside,
    InfiniteLoading,
  },
  created() {
    this.cargarNoticiasPortadas(null);
  },
  data() {
    return {
      page: -1,
      news: [],
    };
  },
  computed: {
    noticiasCarrusel() {
      return this.news.slice(0, 3);
    },
    noticiasSecundarias() {
      return this.news.slice(3);
    },
  },
  methods: {
    cargarNoticiasPortadas($state) {
      this.page += 1;

      newsService.getNews(this.page, 10)
        .then((response) => {
          if (response.data.length) {
            this.news.push(...response.data);
            if ($state) {
              $state.loaded();
            }
          } else if ($state) {
            $state.complete();
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped>
.letras {
  color: #fd621d;
  font-weight: bold;
}
</style>
