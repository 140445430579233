<template>
  <div class="fondo">
    <div class="letras navbar-text">
      <img class="logo" src="./../assets/logo_diario_colonia_marina(2).png">
      <div><b>Información de contacto:</b></div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
             fill="currentColor" class="bi bi-envelope-paper mx-2 icons"
             viewBox="0 0 16 16">
          <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2
     2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2
      2 0 0 0-2-2H4Zm10 4.267.47.25A1 1 0 0 1 15 5.4v.817l-1 .6v-2.55Zm-1
       3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1
        1 1v5.417Zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267v2.55Zm13
         .566v5.734l-4.778-2.867L15 7.383Zm-.035 6.88A1 1 0 0 1 14 15H2a1
          1 0 0 1-.965-.738L8 10.083l6.965 4.18ZM1 13.116V7.383l4.778 2.867L1 13.117Z"/>
        </svg>
        diariocoloniamarina@gmail.com
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-telephone icons mx-2" viewBox="0 0 16 16">
          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c
          -.483.484-.661 1.169
          -.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211
           1.286.033 1.77
          -.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l
          -2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46
          -1.657l.548-2.19a.678.678 0 0 0-.122
          -.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29
           2.98c.329.423.445.974.315 1.494l
          -.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189
          -.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l
          -1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01
          -4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
        </svg>
        (03564)-15501098
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-house-door icons mx-2" viewBox="0 0 16 16">
          <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0
     .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5
      0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354
       1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0
        0-.5.5v4H2.5z"/>
        </svg>
        Colonia Marina-Córdoba
      </div>
      <div class="container">
        <div class="iconos">
          <div class="nav-item">
            <a class="nav-link" rel="noopener" href="https://www.facebook.com/Diario-Colonia-Marina-101703771484849" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                   fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0
                       4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017
                        1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993
                         0-1.303.621-1.303 1.258v1.51h2.218l-.354
                          2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
              </svg>
              <span class="visually-hidden">Facebook</span>
            </a>
          </div>
          <div class="nav-item">
            <a class="nav-link" rel="noopener" href="https://youtube.com" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                   fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                <path d="M8.051 1.999h.089c.822.003 4.987.033
                       6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22
                        1.402l.01.104.022.26.008.104c.065.914.073 1.77.074
                         1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c
                         -.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c
                         -1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927
                         -.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167
                         -.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417
                         -.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v
                         -.122C.002 7.343.01 6.6.064 5.78l.007-.103.003-.052.008-.104.022
                         -.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415
                         -1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171
                         -.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157
                         -2.408L6.4 5.209z"/>
              </svg>
              <span class="visually-hidden">Youtube</span>
            </a>
          </div>
          <div class="nav-item">
            <a class="nav-link" rel="noopener" href="https://wa.me/5493564501098" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0
                       .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0
                        16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926
                        -3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994
                         14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666
                         -2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957
                         -6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557
                          0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615
                          -4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099
                          -.445.099-.133.197-.513.646-.627.775-.114.133-.232.148
                          -.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985
                          -1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087
                          -.088.197-.232.296-.346.1-.114.133-.198.198-.33.065
                          -.134.034-.248-.015-.347-.05-.099-.445-1.076-.612
                          -1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247
                          -.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198
                          -.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394
                           2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38
                           -.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182
                           -.133-.38-.232z"/>
            </svg>
            <span class="visually-hidden">WhatsApp</span>
          </a>
          </div>
          <div class="nav-item">
            <a class="nav-link" rel="noopener" href="https://www.instagram.com/walter.montoya.tv/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                   fill="currentColor" viewBox="0 0 512 512">
                <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62
                        ,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62
                        ,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32
                        ,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480
                        ,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z"/>
                <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0
                        ,1,377.33,162.67Z"/>
                <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1
                        ,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z"/>
              </svg>
              <span class="visually-hidden">Instagram</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer2',
};
</script>

<style scoped>
.fondo {
  background: #1b1b1b;
  margin: 0;
  padding: 0;
}

.letras {
  text-align: center;
  color: white;
  line-height: 40px;
}

.imagen {
  width: 7%;
  margin-top: 30px;
  margin-bottom: 20px;
}
.logo{
  width: 10%;
  margin-bottom: 15px;
}

p {
  color: white;
}

.iconos {
  display: flex;
  align-content: center;
  justify-content: center;
}
.iconos div a svg {
  height: 1.8rem;
  border-radius: 5px;
  color: darkorange;
  padding: 0.25rem 0.1rem;
  font-size: 1.8rem;
  width: 1.8rem;
}

.iconos div a svg:hover {
  background-color: darkorange;
  transform: scale(1);
  color: white;
}

.nav-item {
  margin: 5px;
}
.icons{
  background-color: white;
  height: 1.8rem;
  border-radius: 5px;
  color: black;
  padding: 0.25rem 0.1rem;
  font-size: 1.8rem;
  width: 1.8rem;
}
@media screen and (max-width: 768px) {
  .logo{
    width: 20%;
    margin-bottom: 15px;
  }
  .letras {
    text-align: center;
    color: white;
    line-height: 30px;
    font-size: 15px;
  }

}
</style>
