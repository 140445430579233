import { createStore } from 'vuex';
import advertisementService from '@/services/AdvertisementService';

export default createStore({
  state: {
    publicidades: null,
  },
  mutations: {
    establecerPublicidades(state, publicidades) {
      state.publicidades = publicidades;
    },
  },
  getters: {
    publicidadPrincipal(state) {
      if (state.publicidades === null) {
        return null;
      }

      return state.publicidades.main;
    },
    publicidadesSecundarias(state) {
      if (state.publicidades === null) {
        return [];
      }

      return state.publicidades.secondaries;
    },
  },
  actions: {
    cargarPublicidades(state) {
      advertisementService.getAdvertisements().then((response) => {
        state.commit('establecerPublicidades', response.data);
      });
    },
  },
  modules: {
  },
});
