<template>
  <div class="bg-white wrapper second-header caja">
    <div>
      <h1 class="text-center my-4">Archivo de noticias</h1>
      <div class="container">
        <ul class="list-group mx-1 over mb-4 row">
          <div class="list-group-item list-group-item-action d-flex  mx-0 px-2 row"
               v-for="_news in news" :key="_news.urlId">
            <div class="col-sm-1 col-2 mx-0 px-0">
              <DateTime class="fecha" :date="new Date(_news.createdAt)"
                        :show-only-date="true"></DateTime>
            </div>
            <div class="col-sm-9 col-8 mx-0 px-0">
              <router-link :to="{ name:'Articulo', params: {urlId:_news.urlId} }">
                <p class=" mx-0 px-0 titulo">{{ _news.title }}</p>
              </router-link>
            </div>
            <div class=" col-sm-2 col-2 mx-0 px-0">
              <router-link :to="{ name:'Editar', params: {urlId:_news.urlId} }">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                     fill="currentColor" class="bi bi-pencil mx-0 px-0" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l
            -10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11
            -.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207
             2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0
              1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821
              -1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h
              -.5a.5.5 0 0 1-.468-.325z"/>
                </svg>
              </router-link>
              <a style="cursor: hand" class="float-end mx-0 px-0" @click="eliminar(_news.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                     fill="currentColor" class="bi bi-trash3 mx-0 px-0" viewBox="0 0 16 16">
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v
            -1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0
            -.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994
            -1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1
            -.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm
            -7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47
            -.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998
            -.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1
            -1 0V5a.5.5 0 0 1 .5-.5Z"/>
                </svg>
              </a>
            </div>
          </div>
          <InfiniteLoading @infinite="cargarNoticiasAdicionales">
          </InfiniteLoading>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import newsService from '@/services/NewsService';
import DateTime from '@/components/DateTime.vue';
import InfiniteLoading from 'v3-infinite-loading';
import 'v3-infinite-loading/lib/style.css';

export default {
  name: 'NoticiasListas',
  components: {
    DateTime,
    InfiniteLoading,
  },
  data() {
    return {
      page: 0,
      news: [],
    };
  },
  methods: {
    eliminar(id) {
      newsService.deleteNew(id)
        .then(() => {
          this.news = this.news.filter((_news) => _news.id !== id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarNoticiasAdicionales($state) {
      newsService.getNews(this.page, 10)
        .then((response) => {
          if (response.data.length) {
            this.page += 1;
            this.news.push(...response.data);
            if ($state) {
              $state.loaded();
            }
          } else if ($state) {
            $state.complete();
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>
.titulo {
  word-wrap: break-word;
  font-size: 12px;
  color: black;
  font-weight: bold;
}

.over :hover {
  background-color: lightblue;
}

@media screen and (max-width: 570px) {
  .titulo {
    word-wrap: break-word;
    font-size: 10px;
    color: black;
  }

  .fecha {
    word-wrap: break-word;
    font-size: 8px;
    color: black;

  }
}

@media screen and (max-width: 768px) {
  .titulo {
    word-wrap: break-word;
    font-size: 11px;
    color: black;
  }

  .fecha {
    word-wrap: break-word;
    font-size: 9px;
    color: black;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  .titulo {
    word-wrap: break-word;
    font-size: 11px;
    color: black;
  }

  .fecha {
    word-wrap: break-word;
    font-size: 10px;
    color: black;
  }
}

</style>
