<template>
  <div class="top-menu bg-white second-header wrapper">
    <div class="container">
      <main id="content">
        <div>
          <div>
            <div class="top-section col-12 mt-05">
              <div class="gap-075"></div>
              <div class="mb-3 container fondo">
                <div class="row">
                  <article class="col-md-4 mt-4">
                    <div class=" text-white overflow mb-4">
                      <div class="ratio_360-202 image-wrapper">
                      </div>
                    </div>
                  </article>
                  <article class="col-md-4 mt-4">
                    <div class=" text-white overflow mb-4">
                      <div class="ratio_360-202 image-wrapper">
                      </div>
                    </div>
                  </article>
                  <article class="col-md-4 mt-4">
                    <div class=" text-white overflow mb-4">
                      <div class="ratio_360-202 image-wrapper">
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarruselEsqueleto',
};
</script>

<style scoped>

</style>
