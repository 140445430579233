import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
});

export default {
  getNews(page, limit = 5) {
    return apiClient.get(`/news?page=${page}&limit=${limit}`);
  },
  getSpecificNews(urlId) {
    return apiClient.get(`/news/${urlId}`);
  },
  createNews(data) {
    return apiClient.post('/news', data);
  },
  editSpecificNews(urlId, newData) {
    return apiClient.patch(`/news/${urlId}`, newData);
  },
  deleteNew(urlId) {
    return apiClient.delete(`/news/${urlId}`);
  },
};
