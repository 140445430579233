<template>
  <div class="bg-white wrapper second-header text-center caja">
    <form class="review-form ancho caja wrapper" ref="formulario-cargar-articulo"
          @submit.prevent="guardarArticulo">
      <h4> {{titulo}}</h4>
      <div class="form-outline">
        <label class="form-label" for="">Título</label>
        <input type="text" id="" class="form-control" v-model="articulo.titulo"/>
        <div class="text-danger" v-if="v$.articulo.titulo.$invalid">Campo obligatorio</div>
      </div>
      <div class="form-outline">
        <label class="form-label" for="loginName">Copete</label>
        <textarea type="text" id="" class="form-control"
                  v-model="articulo.copete"></textarea>
        <div class="text-danger" v-if="v$.articulo.copete.$invalid">Campo obligatorio</div>
      </div>
      <div class="form-outline">
        <CargadorImagen @imagen-cargada="imagenCargada"></CargadorImagen>
        <div class="text-danger" v-if="v$.articulo.url_imagen.$invalid">Campo obligatorio</div>
      </div>

      <div class="form-outline">
        <label class="form-label" for="loginName">Cuerpo</label>
        <textarea class="form-control"
                  v-model="articulo.descripcion"></textarea>
        <div class="text-danger" v-if="v$.articulo.descripcion.$invalid">
          Campo obligatorio
        </div>

      </div>
      <div class="form-outline">
        <label class="form-label" for="">ID video de youtube (11 caracteres)</label>
        <input type="text" id="" class="form-control"
               v-model="articulo.video"/>
        <div class="text-danger" v-if="v$.articulo.video.$invalid">
          El ID debe contener 11 caracteres
        </div>
      </div>

      <input class="btn btn-outline-primary my-3" type="submit"
              :disabled="deshabilitarBotonGuardado" :value="nombreBoton" />
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {
  required, url, minLength, maxLength,
} from '@vuelidate/validators';
import CargadorImagen from '@/components/CargadorImagen.vue';

export default {
  name: 'CargarArticulo',
  components: { CargadorImagen },
  props: {
    titulo: {
      required: true,
      type: String,
    },
    nombreBoton: {
      required: true,
      type: String,
    },
    articuloInicial: {
      required: false,
      type: Object,
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  created() {
    if (this.articuloInicial) {
      this.articulo.titulo = this.articuloInicial.title;
      this.articulo.copete = this.articuloInicial.subtitle;
      this.articulo.descripcion = this.articuloInicial.text;
      this.articulo.url_imagen = this.articuloInicial.imageUrl;
      this.articulo.video = this.articuloInicial.youtubeVideoId;
    }
  },
  data() {
    return {
      articulo: {
        titulo: '',
        copete: '',
        url_imagen: '',
        descripcion: '',
        video: '',
      },
    };
  },
  computed: {
    deshabilitarBotonGuardado() {
      return this.v$.$invalid;
    },
  },
  methods: {
    guardarArticulo() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.$emit('formulario-articulo-cargado', this.articulo);
      this.$emit('alert', this.show);
      this.articulo.titulo = '';
      this.articulo.copete = '';
      this.articulo.url_imagen = '';
      this.articulo.descripcion = '';
      this.articulo.video = '';
    },
    imagenCargada(urlImagen) {
      this.articulo.url_imagen = urlImagen;
    },
  },
  validations() {
    return {
      articulo: {
        titulo: {
          required,
        },
        copete: {
          required,
        },
        url_imagen: {
          required,
          url,
        },
        descripcion: {
          required,
        },
        video: {
          minLengthValue: minLength(11),
          maxLengthValue: maxLength(11),
        },
      },
    };
  },
};

</script>

<style scoped>
.review-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 40px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.57);
  box-shadow: 2px 15px -12px rgba(0, 0, 0, 0.57);
}

.ancho {
  width: 70%;
  margin: 0px auto;
}
.caja {
  text-align: center;
}
label {
  text-align: left;
  margin: 7px;
}
input {
  height: 40px;
}
.color{
  background:forestgreen;
  color: white;
  font-weight: bold;
}
@media screen and (max-width: 768px){
.ancho{
  width: 90%;
}
}
</style>
